import {
	Banner,
	Button,
	Card,
	Form,
	FormLayout,
	Loading,
	Page,
	Select,
	Spinner,
	TextField,
	TextStyle,
	Thumbnail,
	Toast,
} from '@shopify/polaris';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../layout/default';
import { ErrorBanner } from '../../utils/components/ErrorBanner';
import {
	LOCAL_STORAGE_KEY,
	MAX_DOCUMENT_SIZE,
	MAX_IMAGE_SIZE,
	SERVER_BASE_URL,
	SUPPORTED_DOCUMENT_MIMETYPES,
	SUPPORTED_IMAGE_MIMETYPES,
} from '../../utils/constants';
import useAxios from '../../utils/hooks/useAxios.hook';
import {
	GetClubsNameListResponse,
	GetPlayerResponse,
	PlayerFullDetails,
	PlayerURLParams,
	UploadFileResponse,
} from '../../utils/types';
import { isEmail } from '../../utils/validations';
import avatar from '../../assets/images/avatar.png';
import { v4 } from 'uuid';

export function EditPlayer() {
	const { registrationNumber } = useParams<PlayerURLParams>();
	const [player, setPlayer] = useState<PlayerFullDetails>(null);
	const [playerS3Id, setPlayerS3Id] = useState<string>();

	const [playerClub, setPlayerClub] = useState(null);
	const [clubs, setClubs] = useState([]);

	const [playerStatus, setPlayerStatus] = useState('unattended');
	const statusOptions = [
		{ label: 'Pending', value: 'unattended' },
		{ label: 'On Hold', value: 'onHold' },
		{ label: 'Approved', value: 'approved' },
		{ label: 'Verified', value: 'fullyApproved' },
	];

	const bloodGroups = [
		{ label: 'None', value: '-' },
		{ label: 'A+', value: 'A+' },
		{ label: 'A-', value: 'A-' },
		{ label: 'B+', value: 'B+' },
		{ label: 'B-', value: 'B-' },
		{ label: 'AB+', value: 'AB+' },
		{ label: 'AB-', value: 'AB-' },
		{ label: 'O+', value: 'O+' },
		{ label: 'O-', value: 'O-' },
	];

	const photoRef = useRef(null);
	const registrationFormRef = useRef(null);
	const affidavitRef = useRef(null);
	const passportRef = useRef(null);
	const iqamaRef = useRef(null);

	const token = localStorage.getItem(LOCAL_STORAGE_KEY);
	const http: AxiosInstance = useAxios(token);

	const [errorToastContent, setErrorToastContent] = useState(
		'Failed to connect with the server. Please try again.',
	);
	const [toastActive, setToastActive] = useState(false);

	const [successToastActive, setSuccessToastActive] = useState(false);
	const [successToastContent, setSuccessToastContent] = useState(
		'Changes saved successfully!',
	);

	const [isError, setIsError] = useState(false);
	const [uploading, setUploading] = useState(false);

	const toggleErrorToastActive = useCallback(
		() => setToastActive((active) => !active),
		[],
	);

	const toggleSuccessToastActive = useCallback(
		() => setSuccessToastActive((active) => !active),
		[],
	);

	const onSelectPhoto = (e) => {
		const file = e.target.files[0];

		if (!file) {
			setErrorToastContent('Please select a file to continue');
			setToastActive(true);
			return;
		}

		if (!SUPPORTED_IMAGE_MIMETYPES.includes(file.type)) {
			setErrorToastContent('Please select an image file');
			setToastActive(true);
			return;
		}

		if (file.size > MAX_IMAGE_SIZE) {
			setErrorToastContent('Only images with less than 500KB are allowed!');
			setToastActive(true);
			return;
		}

		uploadPhoto(file);
	};

	async function uploadPhoto(photoFile: File) {
		setUploading(true);
		try {
			const photoFormData = new FormData();
			photoFormData.append('photo', photoFile);

			const res: UploadFileResponse = await http.post(
				`/player/upload-photo/${playerS3Id}`,
				photoFormData,
			);

			setSuccessToastContent('Player photo updated successfully!');
			setSuccessToastActive(true);
			setPlayer({ ...player, photo: res.data.key });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error('Failed to store the player photo in the server', error);
		} finally {
			setUploading(false);
		}
	}

	function onSelectDocument(e) {
		const file = e.target.files[0];

		if (!file) {
			setErrorToastContent('Please select a file to continue');
			setToastActive(true);
			return;
		}

		if (!SUPPORTED_DOCUMENT_MIMETYPES.includes(file.type)) {
			setErrorToastContent('Please select an image or PDF file');
			setToastActive(true);
			return;
		}

		if (file.size > MAX_DOCUMENT_SIZE) {
			setErrorToastContent('Only files with less than 5MB are allowed!');
			setToastActive(true);
			return;
		}

		switch (e.target.id) {
			case 'registrationForm':
				uploadRegistrationForm(file);
				break;
			case 'affidavit':
				uploadAffidavit(file);
				break;
			case 'passport':
				uploadPassport(file);
				break;
			case 'iqama':
				uploadIqama(file);
				break;
			default:
				break;
		}
	}

	async function uploadRegistrationForm(docFile: File) {
		setUploading(true);
		try {
			const regformFormData = new FormData();
			regformFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-registration-form/${playerS3Id}`,
				regformFormData,
			);

			setSuccessToastContent('Player registration form uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, registrationForm: res.data.key });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error(
				'Failed to store the registration form document in the server',
				error,
			);
		} finally {
			setUploading(false);
		}
	}

	async function uploadAffidavit(docFile: File) {
		setUploading(true);
		try {
			const affidavitFormData = new FormData();
			affidavitFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-affidavit/${playerS3Id}`,
				affidavitFormData,
			);

			setSuccessToastContent('Player affidavit uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, affidavit: res.data.key });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error(
				'Failed to store the affidavit document in the server',
				error,
			);
		} finally {
			setUploading(false);
		}
	}

	async function uploadPassport(docFile: File) {
		setUploading(true);
		try {
			const passportFormData = new FormData();
			passportFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-passport/${playerS3Id}`,
				passportFormData,
			);

			setSuccessToastContent('Player passport uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, passport: res.data.key });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error(
				'Failed to store the passport document in the server',
				error,
			);
		} finally {
			setUploading(false);
		}
	}

	async function uploadIqama(docFile: File) {
		setUploading(true);
		try {
			const iqamaFormData = new FormData();
			iqamaFormData.append('file', docFile);
			const res: UploadFileResponse = await http.post(
				`/player/upload-iqama/${playerS3Id}`,
				iqamaFormData,
			);

			setSuccessToastContent('Player iqama uploaded successfully!');
			setSuccessToastActive(true);

			setPlayer({ ...player, iqama: res.data.key });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Something went wrong! Please try again.');
			setToastActive(true);
			console.error('Failed to store the iqama document in the server', error);
		} finally {
			setUploading(false);
		}
	}

	async function loadPlayer() {
		try {
			const response: GetPlayerResponse = await http.get('/player', {
				params: {
					registrationNumber,
				},
			});

			let s3Id = response.data.player.s3Id;
			if (!s3Id) s3Id = v4();
			setPlayerS3Id(s3Id);

			const clubsList: GetClubsNameListResponse = await http.get(
				'/clubs-names',
			);

			setClubs(clubsList.data.clubs);
			setPlayerClub(response.data.player.clubId);

			const status = Number(response.data.player.status);
			switch (status) {
				case 0:
					setPlayerStatus('unattended');
					break;
				case 1:
					setPlayerStatus('onHold');
					break;
				case 2:
					setPlayerStatus('approved');
					break;
				case 3:
					setPlayerStatus('fullyApproved');
					break;
				default:
					break;
			}
			setPlayer({ ...response.data.player });
		} catch (error) {
			setIsError(true);
			setErrorToastContent('Failed to load player details from the server');
			setToastActive(true);
			console.error('Failed to load player details from the server');
		}
	}

	function isValid() {
		if (player.fullName.trim() === '' || player.phone?.trim() === '') {
			return false;
		}

		if (player.email && !isEmail(player.email)) {
			return false;
		}

		if (player.phone.length < 5) {
			return false;
		}

		return true;
	}

	async function updatePlayer() {
		if (!isValid()) {
			return;
		}

		let status = 0;
		switch (playerStatus) {
			case 'unattended':
				status = 0;
				break;
			case 'onHold':
				status = 1;
				break;
			case 'approved':
				status = 2;
				break;
			case 'fullyApproved':
				status = 3;
				break;
			default:
				break;
		}

		const payload = { ...player, status, clubId: playerClub };
		if (playerClub === 'None') {
			payload.clubId = null;
		}

		setUploading(true);
		try {
			await http.patch('/edit-player', { ...payload });
			setSuccessToastContent('Changes saved successfully!');
			setSuccessToastActive(true);
			loadPlayer();
		} catch (error) {
			console.error('Failed to save the club details to the server', error);
			if (error.response && error.response.status === 409) {
				const msg = error.response.data.message || 'Conflict error!';
				setErrorToastContent(msg);
				setToastActive(true);
			} else {
				setIsError(true);
				setErrorToastContent('Something went wrong! Please try again.');
				setToastActive(true);
			}
		} finally {
			setUploading(false);
		}
	}

	const errorToastMarkup = toastActive ? (
		<Toast
			content={errorToastContent}
			error
			onDismiss={toggleErrorToastActive}
		/>
	) : null;

	const successToastMarkup = successToastActive ? (
		<Toast content={successToastContent} onDismiss={toggleSuccessToastActive} />
	) : null;

	const handleStatusSelectChange = useCallback(
		(value) => setPlayerStatus(value),
		[],
	);

	const handleClubSelectChange = useCallback(
		(value) => setPlayerClub(value),
		[],
	);

	const handleBloodGroupChange = (bloodGroup: string) => {
		setPlayer({ ...player, bloodGroup });
	};

	useEffect(() => {
		loadPlayer();
		// eslint-disable-next-line
	}, []);

	return (
		<DefaultLayout>
			<Page
				primaryAction={{
					content: 'Save Changes',
					disabled: isError || !player || uploading || !isValid(),
					onAction: updatePlayer,
				}}
				title="Edit Player Details"
				breadcrumbs={[
					{ content: 'Player', url: `/players/${registrationNumber}` },
				]}
			>
				{errorToastMarkup}
				{successToastMarkup}
				{isError ? (
					<ErrorBanner />
				) : player === null ? (
					<Loading />
				) : (
					<>
						<Card title="Personal Info" sectioned>
							<Form onSubmit={() => {}}>
								<FormLayout>
									<Thumbnail
										source={
											player.photo
												? SERVER_BASE_URL +
												  '/get-player-image/?key=' +
												  player.photo
												: avatar
										}
										alt="Add Photo"
										size="large"
									/>
									<button
										onClick={() => {
											photoRef.current.click();
										}}
										className="Polaris-Button"
									>
										<input
											type="file"
											id="photo"
											accept="image/*"
											ref={photoRef}
											onChange={onSelectPhoto}
											style={{ display: 'none' }}
										/>
										{uploading ? <Spinner size="small" /> : 'Select Photo'}
									</button>
									<TextField
										value={player.fullName}
										onChange={(fullName) => setPlayer({ ...player, fullName })}
										label="Fullname"
										type="text"
										autoComplete="text"
										requiredIndicator
										placeholder="Enter player fullname. Example: John Doe"
										disabled={uploading}
									/>
									<FormLayout.Group>
										<TextField
											value={player.email}
											onChange={(email) => setPlayer({ ...player, email })}
											label="Email"
											type="email"
											autoComplete="email"
											requiredIndicator
											placeholder="Enter the player's email address"
											disabled={uploading}
										/>

										<TextField
											value={player.phone}
											onChange={(phone) => setPlayer({ ...player, phone })}
											label="Phone"
											type="number"
											autoComplete="tel"
											requiredIndicator
											placeholder="Enter the player's phone number. Example: 9923487462"
											disabled={uploading}
										/>
									</FormLayout.Group>
									<FormLayout.Group>
										<Select
											label="Blood Group"
											options={bloodGroups}
											onChange={handleBloodGroupChange}
											value={player.bloodGroup}
											disabled={uploading}
										/>
										<TextField
											value={player.dateOfBirth}
											onChange={(dateOfBirth) =>
												setPlayer({ ...player, dateOfBirth })
											}
											label="Date of Birth"
											type="text"
											autoComplete="tel"
											placeholder="Example: 09/12/1994 (DD/MM/YYYY)"
											disabled={uploading}
										/>
									</FormLayout.Group>
									<FormLayout.Group>
										<TextField
											value={player.homeAddress}
											onChange={(homeAddress) =>
												setPlayer({ ...player, homeAddress })
											}
											label="Home Address (India)"
											type="text"
											placeholder="Enter your home address (India)"
											disabled={uploading}
											multiline={4}
										/>
										<TextField
											value={player.saudiAddress}
											onChange={(saudiAddress) =>
												setPlayer({ ...player, saudiAddress })
											}
											label="Saudi Address"
											type="text"
											placeholder="Enter your residential address in Saudi Arabia"
											disabled={uploading}
											multiline={4}
										/>
									</FormLayout.Group>
									<TextField
										value={player.companyName}
										onChange={(companyName) =>
											setPlayer({ ...player, companyName })
										}
										label="Company Name"
										type="text"
										autoComplete="text"
										placeholder="Enter the name of your employer"
									/>
								</FormLayout>
							</Form>
						</Card>
						<Card title="DIFA Settings" sectioned>
							<FormLayout>
								<Banner status="warning">
									<b>Critical Settings:</b> Please make sure the intended values
									are selected
								</Banner>
								<Select
									label="Player Verification Status"
									options={statusOptions}
									onChange={handleStatusSelectChange}
									value={playerStatus}
									disabled={uploading}
								/>
								<Select
									label="Club"
									options={clubs}
									onChange={handleClubSelectChange}
									value={playerClub}
									disabled={uploading}
								/>
							</FormLayout>
						</Card>
						<Card title="Document Numbers" sectioned>
							<FormLayout>
								<FormLayout.Group>
									<TextField
										value={player.iqamaNumber}
										onChange={(iqamaNumber) =>
											setPlayer({ ...player, iqamaNumber })
										}
										label="Iqama Number"
										type="text"
										autoComplete="number"
										placeholder="Enter player's iqama document number"
										disabled={uploading}
									/>
									<TextField
										value={player.passportNumber}
										onChange={(passportNumber) =>
											setPlayer({ ...player, passportNumber })
										}
										label="Passport Number"
										type="text"
										autoComplete="number"
										placeholder="Enter player's passport number"
										disabled={uploading}
									/>
								</FormLayout.Group>
							</FormLayout>
						</Card>
						<Card title="Documents" sectioned>
							<div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#E5E5E5',
										borderRadius: '5px 5px 0px 0px',
									}}
								>
									<div style={{ flex: '0.50' }}>
										<TextStyle variation="strong">
											DIFA Registration Form
										</TextStyle>
									</div>
									<div style={{ marginLeft: 'auto' }}>
										{player.registrationForm ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.registrationForm}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}
										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												registrationFormRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="registrationForm"
												accept="image/*,application/pdf"
												ref={registrationFormRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.registrationForm === ''
												? 'Upload File'
												: 'Change File'}
										</button>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#FAFAFA',
									}}
								>
									<div style={{ flex: '0.50' }}>
										<TextStyle variation="strong">Affidavit</TextStyle>
									</div>
									<div style={{ marginLeft: 'auto' }}>
										{player.affidavit ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.affidavit}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}

										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												affidavitRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="affidavit"
												accept="image/*,application/pdf"
												ref={affidavitRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.affidavit === '' ? 'Upload File' : 'Change File'}
										</button>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#E5E5E5',
									}}
								>
									<TextStyle variation="strong">Passport</TextStyle>
									<div style={{ marginLeft: 'auto' }}>
										{player.passport ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.passport}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}
										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												passportRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="passport"
												accept="image/*,application/pdf"
												ref={passportRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.passport === '' ? 'Upload File' : 'Change File'}
										</button>
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '10px',
										backgroundColor: '#FAFAFA',
										borderRadius: '0px 0px 5px 5px',
									}}
								>
									<TextStyle variation="strong">Iqama / Saudi Visa Copy</TextStyle>
									<div style={{ marginLeft: 'auto' }}>
										{player.iqama ? (
											<Button
												primary
												onClick={() => {
													window.open(
														SERVER_BASE_URL +
															`/admin/get-player-document?token=${token}&key=${player.iqama}`,
														'_blank',
													);
												}}
											>
												View Document
											</Button>
										) : null}

										<button
											style={{ marginLeft: '10px' }}
											onClick={() => {
												iqamaRef.current.click();
											}}
											className="Polaris-Button"
											disabled={uploading}
										>
											<input
												type="file"
												id="iqama"
												accept="image/*,application/pdf"
												ref={iqamaRef}
												onChange={onSelectDocument}
												style={{ display: 'none' }}
											/>
											{player.iqama === '' ? 'Upload File' : 'Change File'}
										</button>
									</div>
								</div>
							</div>
						</Card>
					</>
				)}
			</Page>
		</DefaultLayout>
	);
}

export const SERVER_BASE_URL = 'https://difaksa-server.onrender.com/api/v1';
// export const SERVER_BASE_URL = 'http://localhost:8000/api/v1';

export const TRANSFERS_LIMIT = 10;
export const PLAYERS_LIMIT = 100;
export const CLUBS_LIMIT = 20;

export const LOCAL_STORAGE_KEY = 'difa-app-token';

export const APP_CONFIG_KEY = 'config';

export const DEFAULT_CONFIG = {
	showBanner: true,
	playersListLimit: 10,
	clubsListLimit: 10,
};

export const STATUSES_STYLE = {
	0: 'new',
	1: 'critical',
	2: 'success',
	3: 'info',
};

export const STATUSES = {
	0: 'Pending',
	1: 'On Hold',
	2: 'Approved',
	3: 'Verified',
};

export const TRANSFER_STATUSES = {
	0: 'Transfer Pending',
	1: 'Transfer Withheld',
	2: 'Transfer Approved',
	3: 'Transfer Completed',
};

export const MAX_IMAGE_SIZE = 500 * 1024;

export const SUPPORTED_IMAGE_MIMETYPES = [
	'image/jpeg',
	'image/jpg',
	'image/png',
];

export const MAX_DOCUMENT_SIZE = 5 * 1000 * 1024;

export const SUPPORTED_DOCUMENT_MIMETYPES = [
	'image/jpeg',
	'image/jpg',
	'image/png',
	'application/pdf',
];
